.transferred {
    background-color: rgb(216, 216, 216) !important;
  }

.ag-theme-material {
  --ag-row-hover-color: #ffede9;
  --ag-column-hover-color: #ffede9;
  font-family: 'Archivo', 'Arial', sans-serif;
  font-weight: 300;
}
.ag-header-cell:hover {
  background-color: #ffede9 !important;
}